import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import cn from 'classnames';
import { View } from 'src/components/common';
import './styles.scss';
import { Typography } from '@mui/material';

type CustomMenuProps = {
  values: any;
  name: string;
  options: {
    label: string;
    value: string;
  }[];
  customOptions: {
    label: string;
    value: string;
  };
  handleClickOptions: (name: string, value: string) => void;
  handleClickCustomOptions: (name: string, value: string) => void;
  children: React.ReactNode;
  anchorEl: HTMLElement | null;
  handleCloseMenu: () => void;
  handleOpen: (event: React.MouseEvent<HTMLElement>) => void;
  customLabelDisplay: string;
};

export const CustomMenu = ({
  values,
  name,
  options,
  customOptions,
  handleClickOptions,
  handleClickCustomOptions,
  children,
  anchorEl,
  handleCloseMenu,
  handleOpen,
  customLabelDisplay,
}: CustomMenuProps) => {
  const totalOptions = [...options, { label: customLabelDisplay, value: customOptions.value }];
  // eslint-disable-next-line security/detect-object-injection
  const currentValue = totalOptions.find((option) => option.value === values[name]) || {
    label: '',
    value: '',
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <View
        onClick={handleOpen}
        isRow
        className={cn('custom_menu_container', { 'custom_menu_container--focus': open })}
        justify="space-between"
      >
        <View justify="center">{currentValue?.label}</View>
        <View isRow justify="center" className="custom_menu_indicator">
          <span className="custom_menu_indicator_separate" />
          <View
            align="center"
            style={{
              width: '30px',
            }}
          >
            <svg height="20" width="20" viewBox="0 0 20 20" className="custom_menu_indicator_svg">
              <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z" />
            </svg>
          </View>
        </View>
      </View>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
        className="custom_menu--dropdown"
      >
        {options.map((option, id) => (
          <MenuItem
            key={id}
            onClick={() => handleClickOptions(name, option.value)}
            disableRipple
            className={cn('custom_menu--dropdown--item', {
              'custom_menu--dropdown--item--focus': option.value === currentValue.value,
            })}
          >
            {option.label}
          </MenuItem>
        ))}
        <MenuItem
          onClick={() => handleClickCustomOptions(name, customOptions.value)}
          disableRipple
          className={cn({
            'custom_menu--dropdown--item--focus': currentValue.value === customOptions.value,
          })}
        >
          <View>
            <Typography fontSize={14}>{customOptions.label}</Typography>
            {children}
          </View>
        </MenuItem>
      </Menu>
    </div>
  );
};
