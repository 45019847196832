import cn from 'classnames';
import React, { useRef, useState } from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { Callback } from 'src/redux/types';
import { getRandomId } from 'src/utils';
import { isEmpty } from 'src/validations';
import Element from '../Element';
import './styles.scss';
import Input from '../Input';
import { convertWithFormat } from './helpers';

const DateSelector: React.FC<DatePickerProps> = ({
  label,
  errorMessage,
  containerClassName,
  classNames,
  placeholder = 'MM/DD/YYYY',
  dateFormat = 'MM/dd/yyyy',
  required,
  name,
  positionFixed = true,
  hideIcon = false,
  onChange,
  onBlur,
  ...props
}) => {
  const id = useRef<string>(`datepicker-${getRandomId()}`);
  const inputRef = useRef(null);
  // For more information:
  // https://reactdatepicker.com/

  //State hooks
  const [stateHasFocus, setStateHasFocus] = useState(false); //Initial value
  const [stateValue, setStateValue] = useState<string | null>(props.value);
  const hasTime = dateFormat !== 'MM/dd/yyyy';
  const handleChange = (value: Date) => {
    if (!value) {
      setStateValue('');
    }
    onChange(name, value);
  };

  const handleBlur = (event: any) => {
    if (!props.value) {
      setStateValue('');
    } else {
      setStateValue(convertWithFormat(props.value, hasTime));
    }
    setStateHasFocus(false);
    if (onBlur) {
      onBlur(event);
    }
  };

  const handleFocus = (event: any) => {
    setStateHasFocus(true);
    if (props.onFocus) {
      props.onFocus(event);
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.keyCode === 9 || event.keyCode === 13) {
      // Tab key or Enter key
      if (!props.value) {
        setStateValue('');
      } else {
        setStateValue(convertWithFormat(props.value, hasTime));
      }

      setStateHasFocus(false);
    }

    return;
  };

  const handleChangeRaw = (event: any) => {
    const value = event.target.value;
    setStateValue(value);
    if (event.key === 'Enter') return;
    return setStateHasFocus(true);
  };

  const hasError = !isEmpty(errorMessage);
  return (
    <Element
      id={id.current}
      errorMessage={errorMessage}
      label={label}
      className={cn(
        'cmp-datepicker',
        { 'cmp-datepicker__icon--hide': hideIcon },
        containerClassName
      )}
      required={required}
    >
      <DatePicker
        onInputClick={() => setStateHasFocus(true)}
        onClickOutside={() => setStateHasFocus(false)}
        name={name}
        id={id.current}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        placeholderText={placeholder}
        className={cn(
          'cmp-datepicker__input',
          { 'cmp-datepicker__input--error': hasError },
          classNames
        )}
        showPopperArrow={false}
        dateFormat={dateFormat}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        popperProps={{
          positionFixed: positionFixed,
        }}
        portalId="root"
        disabledKeyboardNavigation
        {...props}
        onKeyDown={handleKeyDown}
        value={stateValue}
        customInput={<Input ref={inputRef} onChange={handleKeyDown} />}
        onChangeRaw={handleChangeRaw}
        open={stateHasFocus}
      />
    </Element>
  );
};

type BaseDatePickerProps = Pick<
  ReactDatePickerProps,
  Exclude<keyof ReactDatePickerProps, 'onChange' | 'onBlur'>
>;

export type DatePickerProps = BaseDatePickerProps & {
  errorMessage?: string;
  containerClassName?: string;
  classNames?: string;
  placeholder?: string;
  mask?: string;
  label?: string | React.ReactNode;
  hasDifferentValue?: boolean;
  positionFixed?: boolean;
  hideIcon?: boolean;
  onChange: Callback;
  onBlur?: Callback;
};

export default DateSelector;
