import dayjs from 'dayjs';

export const convertStringInputRangeToDate = (value: string, formatForHandle: string[]) => {
  const checkFormat = (date: string) => {
    return dayjs(date, formatForHandle, true).isValid();
  };
  return checkFormat(value) ? dayjs(value, formatForHandle).toDate() : null;
};

export const checkFormat = (date: string) => {
  const regex =
    /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4} (0[1-9]|1[0-2]):[0-5][0-9] (AM|PM|am|pm)$/;
  return regex.test(date);
};

export const convertWithFormat = (date: string | null | Date, hasTime: boolean) => {
  if (!date) return dayjs().format(hasTime ? 'MM/DD/YYYY hh:mm A' : 'MM/DD/YYYY');
  const newDate = dayjs(
    date,
    [
      'MM/DD/YYYY hh:mm A',
      'MM/DD/YYYY hh:mm a',
      'MM/DD/YYYY',
      'MM/DD/YYYY h:m a',
      'MM/DD/YYYY h:m A',
    ],
    true
  );
  return newDate.format(hasTime ? 'MM/DD/YYYY hh:mm A' : 'MM/DD/YYYY');
};
